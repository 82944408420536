import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/database';
import { UtilsService } from '@app-core/utils/utils.service';
import { Observable, Subscription } from 'rxjs';
import { ProxyObject } from '@app-core/data/base';
import { NbToastrService } from '@nebular/theme';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '@app-core/data/users';
import { UserService } from '@app-core/data/users.service';

import { ChildEvent } from '@angular/fire/database/interfaces';

import * as firebase from 'firebase';

@Injectable({ providedIn: 'root' })
export class FirebaseService implements OnDestroy
{
	// protected static URL: string = 'https://buas.vamidicreations.nl/core/api/';

	protected static headers: HttpHeaders = new HttpHeaders({
		'Content-Type': 'application/json',
	});

	private static checkTbl(tbl: string): boolean
	{
		return tbl !== '';
	}

	public onTableAddEvent: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * @unused
	 */
	// public get lastUsedTable()
	// {
	// 	return this.lastUsedTblName;
	// }

	public get table()
	{
		return this.tblName;
	}

	public getExcludedTables()
	{
		return this.excludedTables;
	}

	public getProxyObject(__: string): ProxyObject | null
	{
		return null;
	}

	private excludedTables: string[] = ['users', 'revisions', 'relations', 'projects'];

	protected tblName = '';
	protected lastUsedTblName = '';

	protected user: User | null = null;

	// protected readonly URL: string = '';

	// TODO place this inside table data.
	// protected userRoles: Array<string>;

	// Main subscription to all events
	protected mainSubscription: Subscription = new Subscription();

	constructor(
		protected afd: AngularFireDatabase,
		protected http: HttpClient,
		protected toastrService: NbToastrService,
		protected userService: UserService)
	{
		this.mainSubscription.add(this.userService.getUser().subscribe((user: User) => this.user = user));
	}

	ngOnDestroy(): void
	{
		// Unsubscribe to all table events
		this.mainSubscription.unsubscribe();
	}

	/**
	 * @brief - Retrieve table data of the database
	 * @param tblName - Pass in the table name
	 * @param events - Events where should listen to.
	 */
	public getTableData$<T = any>(
		tblName: string = '', events: ChildEvent[] = ['child_added', 'child_changed', 'child_removed'],
	): Observable<SnapshotAction<T>[]>
	{
		let tbl = this.tblName;

		// if we override the tblName
		if(tblName !== '')
			tbl = tblName;

		this.lastUsedTblName = tbl;

		const tableRef = this.getList<any>(tbl);

		// assign snapshot changes
		return tableRef.snapshotChanges(events);
	}

	/**
	 * @brief - Set the query to a new table
	 * @param newTblName
	 */
	public setTblName(newTblName: string)
	{
		if(newTblName === '')
			UtilsService.onError('There is no list defined!');

		this.tblName = newTblName;
	}

/*
	public getShallowQuery(location: string, query: string = ''): Observable<any>
	{
		console.log(environment.firebase.databaseURL + '/' + location + '.json?' + query + '&shallow=true');
		return this.http.get(
			environment.firebase.databaseURL + '/' + location + '.json?' + query + '&shallow=true',
			{ headers: FirebaseService.headers })
			.pipe(map((response) => response ));
	}
*/

	/**
	 * @brief -
	 * @param tblName
	 */
	public getRef(tblName: string = '')
	{
		let tbl = this.tblName;

		if(tblName !== '')
			tbl = tblName;

		this.lastUsedTblName = tbl;

		// get the list
		return this.afd.database.ref(tbl);
	}

	public getItem(id: number, tblName: string = '')
	{
		let tbl = this.tblName;

		// if we override the tblName
		if(tblName !== '')
			tbl = tblName;

		this.lastUsedTblName = tbl;

		// /tableName/id
		return this.afd.object('/'+ tbl + '/' + String(id));
	}

	public getItemByString(id: string, tblName: string = '')
	{
		let tbl = this.tblName;

		// if we override the tblName
		if(tblName !== '')
			tbl = tblName;

		this.lastUsedTblName = tbl;

		// /tableName/id
		return this.afd.object('/'+ tbl + '/' + id);
	}

	/**
	 * @brief - Insert data
	 * @param tblRef
	 * @param data
	 * @param tblName
	 */
	public insertData(tblRef: string, data: any, tblName: string = '')
	{
		if(!this.userService.canEdit)
			throw new Error('You don\'t have the permission!');

		let tbl = this.tblName;

		// if we override the tblName
		if(tblName !== '')
			tbl = tblName;

		this.lastUsedTblName = tbl;

		if(data.hasOwnProperty('id'))
		{
			delete data['id'];
		}

		return this.getId(tbl + '/metadata/lastUID').then((transactionResult) =>
		{
			const newId = transactionResult.snapshot.val();
			return this.insertItem(newId, data, tblRef);
		});
	}

	/**
	 *
	 * @param id - the Id of the data.
	 * @param tblRef - tableRef to insert the revisions to
	 * @param newData - data to insert
	 * @param oldData - old data for the revisions
	 * @param tblName - tblName to override where we should store the data
	 */
	public async updateData(id: string | number, tblRef: string,
		newData: ProxyObject, oldData: ProxyObject = null, tblName: string = '',
	): Promise<void | string>
	{
		if(!this.userService.canEdit)
			throw new Error('You don\'t have the permission!');

		let tbl = this.tblName;

		// if we override the tblName
		if(tblName !== '')
			tbl = tblName;

		this.lastUsedTblName = tbl;

		if(FirebaseService.checkTbl(tbl))
		{
			const copiedOldData: ProxyObject = oldData ?  UtilsService.copyObj(oldData) : null;
			if (copiedOldData && copiedOldData.hasOwnProperty('id') )
			{
				delete copiedOldData['id'];
			}

			const copiedData = UtilsService.copyObj(newData);

			// Check for the old property name to avoid a ReferenceError in strict mode.
			if (copiedData.hasOwnProperty('id'))
			{
				delete copiedData['id'];
			}

			return this.updateItem(id, copiedData, true, tbl);
		}

		return Promise.reject('Couldn\'t update data');
	}

	/**
	 * @brief - Insert data into firebase.
	 * Firebase will generate a key for you.
	 * @param data - data you want to insert
	 * @param tblName - (optional)
	 * @return
	 */
	public insert(data: any, tblName: string = ''): firebase.database.ThenableReference
	{
		if(!this.userService.canEdit)
			throw new Error('You don\'t have the permission!');

		let tbl = this.tblName;

		// if we override the tblName
		if(tblName !== '')
			tbl = tblName;

		this.lastUsedTblName = tbl;

		if(data.hasOwnProperty('id'))
		{
			delete data['id'];
		}

		return this.getList<any>(tbl).push(data);
	}

	/**
	 * @brief - Insert data into firebase.
	 * Hereby you have to give firebase an ID to work with.
	 * @param id
	 * @param data
	 * @param tblName
	 */
	public insertItem(id: number, data: any, tblName: string = '')
	{
		let tbl = this.tblName;

		// if we override the tblName
		if(tblName !== '')
			tbl = tblName;

		this.lastUsedTblName = tbl;

		return this.getList<any>(tbl).set(String(id), data);
	}

	/**
	 *
	 * @param id
	 * @param newData
	 * @param update
	 * @param tblName
	 */
	public updateItem(
		id: number | string, newData: any, update: boolean = true, tblName: string = ''): Promise<void|string>
	{
		if(!this.userService.canEdit)
			throw new Error('You don\'t have the permission!');

		let tbl = this.tblName;

		// if we override the tblName
		if(tblName !== '')
			tbl = tblName;

		this.lastUsedTblName = tbl;

		if(FirebaseService.checkTbl(tbl))
		{
			// Copy the data to a new object because we don't want to delete properties
			const copiedData = UtilsService.copyObj(newData);

			// Check for the old property name to avoid a ReferenceError in strict mode.
			if (copiedData.hasOwnProperty('id') )
			{
				delete copiedData['id'];
			}

			if(update)
			{
				return this.afd.object(tbl + '/' + String(id)).update(copiedData);
			}

			return this.afd.object('/' + tbl + '/' + String(id)).set(copiedData);
		}

		return Promise.reject('Couldn\'t insert or update item');
	}

	public updateTable(tableName: string, data: Object, update: boolean = true): Promise<void>
	{
		let tbl = this.tblName;

		// if we override the tblName
		if(tableName !== '')
			tbl = tableName;

		this.lastUsedTblName = tbl;

		if(FirebaseService.checkTbl(tbl))
		{
			if(update)
				return this.afd.object('/' + tbl).update(data);
			else
				return this.afd.object('/' + tbl).set(data);
		}

		return Promise.resolve();
	}

	/**
	 * @brief - Save the sheets
	public saveSheet(title: string, id: string, data: any)
	{
		// return this.http.put<any>(FirebaseService.URL + 'projects/management/' + title,
		// 	JSON.stringify(data), FirebaseService.httpOptions)
		// 	.pipe(
		// 		retry(1),
		// 		catchError(this.errorHandl)
		// 	)
	}
	 */

	/**
	 * @brief - Get latest id of the table we are querying from.
	 * Declare a function that increment a counter in a transaction
	 */
	private getId(tblName: string): Promise<any>
	{
		let tbl = this.tblName;

		// if we override the tblName
		if(tblName !== '')
			tbl = tblName;

		const counterRef = this.getRef(tbl);
		return counterRef.transaction((lastUID) => {
			return lastUID + 1;
		});
	}

	private getList<T>(tblName: string = '')
	{
		let tbl = this.tblName;

		// if we override the tblName
		if(tblName !== '')
			tbl = tblName;

		this.lastUsedTblName = tbl;

		return this.afd.list<T>(tbl);
	}
}
