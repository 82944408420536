import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// import { MenuService } from '@app-core/utils/menu.service';
import { Node } from '@app-core/data/nodes';
import { DOCUMENT } from '@angular/common';


@Component({
	selector: 'ngx-navbar-layout',
	templateUrl: './navbar-layout.component.html',
	styleUrls: ['./navbar-layout.component.scss'],
})
export class NavbarLayoutComponent implements OnInit, AfterViewInit, OnDestroy
{
	public nodes: Node[] = [];

	// Subscription for the router
	private mainSubscription: Subscription = new Subscription();

	// Page name
	private pageName: String = '';

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private route: ActivatedRoute,
		private router: Router,
		// private menuService: MenuService,
	)
	{
	}


	public ngOnInit()
	{
		this.document.body.classList.add('home');

		this.pageName = this.router.url.substr(this.router.url.lastIndexOf('/') + 1);

		this.mainSubscription.add(this.router.events.subscribe((event) =>
		{
			if(event instanceof NavigationEnd)
			{
				// Do something with the incoming data
			}
		}));

		/*
		// TODO this will come from the page information
		this.mainSubscription.add(this.menuService.siteStructure('primary-menu').subscribe((result) => {
			console.log(result);
			if(result.error === false)
			{
				const children = result.menu.links.children;
				this.nodes = children ? children : [];
			}
		}));
		 */
	}

	public ngAfterViewInit()
	{
		$(window).scroll(function()
		{
			const $w = $(this),
				st = $w.scrollTop(),
				navbar = $('.pb_navbar'),
				sd = $('.js-scroll-wrap');

			if (st > 150) {
				if ( !navbar.hasClass('scrolled') ) {
					navbar.addClass('scrolled');
				}
			}
			if (st < 150) {
				if ( navbar.hasClass('scrolled') ) {
					navbar.removeClass('scrolled sleep');
				}
			}
			if ( st > 350 ) {
				if ( !navbar.hasClass('awake') ) {
					navbar.addClass('awake');
				}

				if(sd.length > 0) {
					sd.addClass('sleep');
				}
			}
			if ( st < 350 ) {
				if ( navbar.hasClass('awake') ) {
					navbar.removeClass('awake');
					navbar.addClass('sleep');
				}
				if(sd.length > 0) {
					sd.removeClass('sleep');
				}
			}
		});
	}

	public ngOnDestroy()
	{
		this.document.body.classList.remove('home');

		this.mainSubscription.unsubscribe();
	}
}
