import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NbDateService } from '@nebular/theme';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { UtilsService } from '@app-core/utils';
import { CaptchaResponse } from '@app-core/data/captcha';
import { OpeningHour, OpeningInfo } from '@app-core/data/opening-info';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
	selector: 'ngx-reservation',
	templateUrl: './reservation.component.html',
	styleUrls: ['./reservation.component.scss'],
})
export class ReservationComponent implements OnInit, AfterViewInit
{
	@ViewChild('dateTimePicker', { static: true })
	public datetimePicker: any;

	public form: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required, Validators.minLength(3)]),
		email: new FormControl('', [Validators.required, Validators.email]),
		datetime: new FormControl('', [Validators.required,
			(control: AbstractControl) => this.checkDateValidator(control),
		]),
		persons: new FormControl('none', Validators.required),
		tel: new FormControl('', [
			Validators.required,
			Validators.pattern('^[0-9]*$'),
			Validators.minLength(10),
			Validators.maxLength(10) ],
		),
		comment: new FormControl(''),
		captcha: new FormControl(null, Validators.required),
	});

	public msgObject = {
		error: false,
		error_msg: '',
	};

	public get SiteKey()
	{
		return this.recaptchaSettings.siteKey;
	}

	public min: Date;

	protected hours: OpeningHour[];

	protected subscription: Subscription = new Subscription();

	protected captchaRes: CaptchaResponse = null;

	constructor(
		@Inject(RECAPTCHA_SETTINGS) private recaptchaSettings: RecaptchaSettings,
		protected http: HttpClient,
		protected dateService: NbDateService<Date>,
	) { }

	public ngOnInit()
	{
		this.subscription.add(this.http.get<OpeningInfo>('assets/data/opening-hours.json')
			.subscribe(v => this.hours = v.openingHours));

		console.log(this.datetimePicker);
	}

	public ngAfterViewInit(): void { }

	public sendForm()
	{
		// the form should be valid and the captcha response.
		if(this.form.valid && this.captchaRes && !this.captchaRes.error)
		{
			UtilsService.sendEnQuery(this.http, this.form.value, environment.devUrl + '/modules/frontend_forms/reservation.form.php')
				.subscribe(result =>
				{
					if(result)
					{
						this.msgObject.error = result.error || false;
						this.msgObject.error_msg = result.error_msg || '';

						// this.cd.detectChanges();
						this.form.reset();

						setTimeout(() => {
							this.captchaRes = null;
							this.msgObject.error = false;
							this.msgObject.error_msg = '';
							// this.cd.detectChanges();
						}, 5000);
					}
				},
			);
		}
		else {
			this.msgObject.error = true;
			this.msgObject.error_msg = 'Er is fout opgetreden probeer het later opnieuw!';
			setTimeout(() => {
				this.msgObject.error = false;
				this.msgObject.error_msg = '';
				// this.cd.detectChanges();
			}, 2000);
		}

	}

	public errored(_: string[])
	{
		this.msgObject.error = true;
		this.msgObject.error_msg = 'Er is fout opgetreden bij het invoeren van de captcha';

		setTimeout(() => {
			this.msgObject.error_msg = '';
			// this.cd.detectChanges();
		}, 2000);
		// console.warn(`reCAPTCHA error encountered`);
	}

	public submit(captchaResponse: string): void
	{
		this.http.post<CaptchaResponse>(
			`${environment.devUrl}/modules/frontend_verify_recaptcha/verify.php`,
			JSON.stringify({
					response: captchaResponse,
				},
			), { headers: { 'Content-Type': 'application/json' } })
		.subscribe((res) => this.captchaRes = res);
	}

	public checkDateValidator(control: AbstractControl): ValidationErrors
	{
		const date: Date = new Date(control.value);

		// check if the date is lower than now because that is obviously not possible
		if(date.getTime() < Date.now())
		{
			return { invalidDate: control.value }
		}
		// now check if the date is between the opening hours
		if(this.hours)
		{
			const currentDay = this.hours[date.getDay() - 1].time;
			const timeTables = currentDay.split('-');

			if(timeTables.length)
			{
				const beginTime = this.getTimeFromString(timeTables[0], date);
				const endTime = this.getTimeFromString(timeTables[1], date);
				return date < beginTime || date > endTime ? { invalidDate: control.value } : null;
			}
		}
		return null;
	}

	protected getTimeFromString(parseTime: string, customDay: Date = null): Date
	{
		const time = customDay ? customDay : new Date(Date.now());
		const pieces = parseTime.split(':');
		if (pieces.length === 2)
		{
			const hour = parseInt(pieces[0], 10);
			const minute = parseInt(pieces[1], 10);

			time.setHours(hour);
			time.setMinutes(minute);
			time.setSeconds(0);
		}

		return time;
	}
}
