export const firebase = {
	apiKey: 'AIzaSyArwUVS2QrdPjfYywRIVjn1gptnUx9QIQ4',
	authDomain: 'pandalemmer-dev.firebaseapp.com',
	databaseURL: 'https://pandalemmer-dev.firebaseio.com',
	projectId: 'pandalemmer-dev',
	storageBucket: 'pandalemmer-dev.appspot.com',
	messagingSenderId: '2274439399',
	appId: '1:2274439399:web:0462d73b8f47e4f65e3242',
	measurementId: 'G-662NL8Z0JC',

	/*
	firebase: {
		apiKey: 'AIzaSyCvGqp6sgKw9Dqh6DugkwJ8R5lpygd3T0I',
		authDomain: 'buas-prototype.firebaseapp.com',
		databaseURL: 'https://buas-prototype.firebaseio.com',
		projectId: 'buas-prototype',
		storageBucket: 'buas-prototype.appspot.com',
		messagingSenderId: '395326151182',
		appId: '1:395326151182:web:909703e6e6be6e07d5fa5f',
	},
	*/
};
