import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Menu } from '@app-core/data/menu';
import { NbIconLibraries } from '@nebular/theme';
import { UtilsService } from '@app-core/utils';

interface Service {
	alert: { show: boolean, text: string },
	information: string;
	additionalInformation: string;
	services: Menu[];
}

@Component({
	selector: 'ngx-catering-service',
	templateUrl: './catering-service.component.html',
	styleUrls: ['./menu-service.component.scss'],
})
export class CateringServiceComponent implements OnInit, AfterViewInit
{
	public utilsService = UtilsService;

	public service$:
		BehaviorSubject<Service> = new BehaviorSubject(null);

	public customClass: string = '';

	protected subscription: Subscription = new Subscription();

	constructor(private http: HttpClient, private iconLibraries: NbIconLibraries) { }

	public ngOnInit()
	{
		this.subscription.add(this.http.get<Service>('assets/data/catering.json').subscribe(v => this.service$.next(v)));

		this.iconLibraries.registerFontPack('font-awesome', { iconClassPrefix: 'fa', packClass: 'fa' });
	}

	public ngAfterViewInit(): void
	{
		/*------------------
		Background Set
		--------------------*/
		$('.set-bg').each(function() {
			const bg = $(this).data('setbg');
			$(this).css('background-image', 'url(' + bg + ')');
		});

		$('.set-bg-color').each(function() {
			const bg = $(this).data('set-bg-color');
			$(this).css('background-color', bg);
		});

		$('#tohash').on('click', function(){
			$('html, body').animate({scrollTop: $(this.hash).offset().top - 5}, 1000);
			return false;
		});
	}
}
