import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
	NbAuthComponent,
	NbRequestPasswordComponent,
	NbResetPasswordComponent,
} from '@nebular/auth';
import {
	AngularFireAuthGuard,
	redirectLoggedInTo,
	redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { NgxFirebaseLoginComponent } from './pages/auth/firebase-login.component';
import { NgxFirebaseLogoutComponent } from './pages/auth/firebase-logout.component';
import { NgxFirebaseRegisterComponent } from './pages/auth/firebase-register.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth/login']);
const redirectLoggedInToDatabase = () => redirectLoggedInTo(['dashboard/']);

export const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('app/home/home-pages.module')
			.then(m => m.HomePagesModule),
	},
	{
		path: 'dashboard',
		loadChildren: () => import('app/dashboard/dashboard.module')
			.then(m => m.DashboardModule),
		canActivate: [ AngularFireAuthGuard ],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: 'auth',
		component: NbAuthComponent,
		children: [
			{
				path: '',
				component: NgxFirebaseLoginComponent,
				canActivate: [ AngularFireAuthGuard ],
				data: { authGuardPipe: redirectLoggedInToDatabase },
			},
			{
				path: 'login',
				component: NgxFirebaseLoginComponent,
				canActivate: [ AngularFireAuthGuard ],
				data: { authGuardPipe: redirectLoggedInToDatabase },
			},
			{
				path: 'register',
				component: NgxFirebaseRegisterComponent,
				data: { authGuardPipe: redirectLoggedInToDatabase },
			},
			{
				path: 'logout',
				component: NgxFirebaseLogoutComponent,
			},
			{
				path: 'request-password',
				component: NbRequestPasswordComponent,
			},
			{
				path: 'reset-password',
				component: NbResetPasswordComponent,
				canActivate: [ AngularFireAuthGuard ],
				data: { authGuardPipe: redirectUnauthorizedToLogin },
			},
		],
	},
	// TODO replace with notfound component for website
	{ path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
	useHash: false,
	paramsInheritanceStrategy: 'always',
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
