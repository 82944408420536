export * from './header/header.component';
export * from './footer/index';
export * from './reservation/reservation.component';
export * from './contact/contact.component';
export * from './search-input/search-input.component';
export * from './tiny-mce/tiny-mce.component';
export * from './menu-service/menu-service.component';
export * from './menu-service/catering-service.component';
export * from './review/review.component';

