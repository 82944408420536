import { Component } from '@angular/core';

@Component({
	selector: 'ngx-sub-footer',
	templateUrl: './sub-footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class SubFooterComponent
{
}
