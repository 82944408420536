import { of as observableOf, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Contacts, RecentUsers, User, UserData, UserModel } from '../data/users';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { SnapshotAction } from '@angular/fire/database/interfaces';

import { NbToastrService } from '@nebular/theme';
import * as firebase from 'firebase';
import * as _ from 'lodash';
import { UtilsService } from '@app-core/utils';

@Injectable()
export class UserService extends UserData implements OnDestroy
{
	public get isAdmin(): boolean
	{
		const allowed = ['admin'];
		return this.matchingRole(allowed);
	}

	public get canRead(): boolean
	{
		const allowed = ['admin', 'author', 'reader'];
		return this.matchingRole(allowed);
	}

	public get canEdit(): boolean
	{
		const allowed = ['admin', 'author'];
		return this.matchingRole(allowed);
	}

	public get canDelete(): boolean
	{
		const allowed = ['admin'];
		return this.matchingRole(allowed);
	}

	public get isVerified()
	{
		return this.firebaseUser ? this.firebaseUser.emailVerified : false;
	}

	protected subscription: Subscription = new Subscription();

	private user: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(new UserModel());

	private users: Map<string, UserModel> = new Map<string, UserModel>();

	private members: UserModel[] = [];

	private contacts: Contacts[] = [];

	private recentUsers: RecentUsers[] = [];

	private firebaseUser: firebase.User = null;

	private userRoles: string[] = [
		'reader',
		'author',
		'admin',
	];

	constructor(
		protected afd: AngularFireDatabase,
		protected afAuth: AngularFireAuth,
		protected toastrService: NbToastrService,
	)
	{
		super();

		this.subscription.add(this.afAuth.authState.pipe(
			switchMap((user: firebase.User ) =>
			{
				if (user)
				{
					this.firebaseUser = user;
					user.getIdToken().then((token) =>
					{
						console.log(token)
					});

					const userRef$ = this.afd.object<any>(`users/${user.uid}`);
					return userRef$.snapshotChanges();
				} else {
					this.user.next(null);
					return of(null);
				}
			}),
		).subscribe((snapshot: SnapshotAction<any> | null) =>
		{
			if(snapshot.payload.exists())
			{
				const userModel: UserModel = { uid: snapshot.key, ...snapshot.payload.val() };

				this.user.next(userModel);

				this.setUser(snapshot.key, userModel);
				return;
			} else
			// if we dont have the snapshot, but we are logged in make the entry in the database
			{
				const split = this.firebaseUser.displayName ? this.firebaseUser.displayName.split(' ', 2) : null;
				const newUser: User =
				{
					uid: this.firebaseUser.uid,
					pages: {},
					articles: {},
					metadata: {
						displayName: this.firebaseUser.displayName ?? '',
						email: this.firebaseUser.email,
						firstName: split ? split[0] : '',
						lastName: split ? split[1] : '',
						photoURL: '',
						created_at: UtilsService.timestamp,
						updated_at: UtilsService.timestamp,
					},
				};

				const userModel: UserModel = { uid: snapshot.key, ...snapshot.payload.val() };

				this.user.next(userModel);

				this.setUser(snapshot.key, userModel);

				this.afd.list(`users`).update(newUser.uid, newUser).then(() => {
					UtilsService.showToast(
						this.toastrService,
						'User info updated!',
						'User successfully inserted',
					)
				});
			}

			this.user.next(null);
		}));
	}

	ngOnDestroy()
	{
		this.subscription.unsubscribe();
	}

	public setUser(key: string, newUser: UserModel, current: boolean = true)
	{
		let user: UserModel | null;
		if(this.users.has(key))
		{
			user = { uid: key, ...this.users.get(key) };
		}
		else
		{
			user = { uid: key, ...newUser };
			this.users.set(key, newUser);
		}

		if(current)
		{
			this.user.next(user);
		}
	}

	public getUser(): BehaviorSubject<User>
	{
		// @ts-ignore
		return this.user;
	}

	public getMembers(): Observable<User[]>
	{
		// @ts-ignore
		return observableOf(this.members);
	}

	public getContacts(): Observable<Contacts[]>
	{
		return observableOf(this.contacts);
	}

	public getRecentUsers(): Observable<RecentUsers[]>
	{
		return observableOf(this.recentUsers);
	}

	public async setPersistence(persistence: firebase.auth.Auth.Persistence)
	{
		return this.afAuth.setPersistence(persistence);
	}

	public updateUserInfo(profile: User)
	{
		if(this.afAuth.user)
		{
			return this.afAuth.currentUser.then((user) =>
			{
				return user.updateProfile({
					displayName: profile.metadata.displayName,
					photoURL: profile.metadata.photoURL,
				});
			});
		}
	}

	public resendMail(callback = () => {})
	{
		this.firebaseUser.sendEmailVerification().then(callback);
	}

	private matchingRole(allowedRoles: any[]): boolean
	{
		// TODO retrieve current project
		return !_.isEmpty(_.intersection(allowedRoles, this.userRoles));
	}
}
