import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OpeningHour, OpeningInfo } from '@app-core/data/opening-info';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
	selector: 'ngx-footer-home',
	templateUrl: './footer-home.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterHomeComponent implements OnInit, OnDestroy
{
	public openingInfo$: BehaviorSubject<OpeningInfo> = new BehaviorSubject({
		tel: '0514-564800',
		additionalTel: '06-19116818',
		mail: 'info@pandalemmer.nl',
		external: {
			text: 'Bestel online!',
			link: 'https://foodtown.nl/pandalemmer/',
		},
		openingHours: [],
	});

	private subscription: Subscription = new Subscription();

	constructor(private http: HttpClient) { }

	public ngOnInit(): void
	{
		this.subscription.add(this.http.get<OpeningInfo>('assets/data/opening-hours.json').subscribe(v => {
			this.openingInfo$.next(v);
		}));
	}

	public ngOnDestroy(): void
	{
		this.subscription.unsubscribe();
	}
}
