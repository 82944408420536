import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OpeningInfo } from '@app-core/data/opening-info';
import { CaptchaResponse } from '@app-core/data/captcha';
import { UtilsService } from '@app-core/utils';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'ngx-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit
{
	public get SiteKey()
	{
		return this.recaptchaSettings.siteKey;
	}

	public form: FormGroup = new FormGroup({
		contactName: new FormControl('', [Validators.required, Validators.minLength(3)]),
		contactEmail: new FormControl('', [ Validators.required, Validators.email ]),
		contactTel: new FormControl('', [
			Validators.required,
			Validators.pattern('^[0-9]*$'),
			Validators.minLength(10),
			Validators.maxLength(10) ],
		),
		contactSubject: new FormControl('', [ Validators.required, Validators.minLength(3) ]),
		contactComment: new FormControl('', [ Validators.required, Validators.minLength(3) ]),
		captcha: new FormControl(null, Validators.required),
	});

	public msgObject = {
		error: false,
		error_msg: '',
	};

	public openingInfo$: BehaviorSubject<OpeningInfo> = new BehaviorSubject({
		tel: '0514-564800',
		additionalTel: '06-19116818',
		mail: 'info@pandalemmer.nl',
		external: {
			text: 'Bestel online!',
			link: 'https://foodtown.nl/pandalemmer/',
		},
		openingHours: [],
	});

	protected captchaRes: CaptchaResponse = null;

	private subscription: Subscription = new Subscription();

	constructor(
		@Inject(RECAPTCHA_SETTINGS) private recaptchaSettings: RecaptchaSettings,
		private http: HttpClient,
	) { }

	public ngOnInit(): void
	{
		this.subscription.add(this.http.get<OpeningInfo>('assets/data/opening-hours.json').subscribe(v => {
			this.openingInfo$.next(v);
		}));
	}

	public errored(_: string[])
	{
		this.msgObject.error = true;
		this.msgObject.error_msg = 'Er is fout opgetreden bij het invoeren van de captcha';

		setTimeout(() => {
			this.msgObject.error_msg = '';
			// this.cd.detectChanges();
		}, 2000);
		// console.warn(`reCAPTCHA error encountered`);
	}

	public submit(captchaResponse: string): void
	{
		this.http.post<CaptchaResponse>(
			`${environment.devUrl}/modules/frontend_verify_recaptcha/verify.php`,
			JSON.stringify({
					response: captchaResponse,
				},
			), { headers: { 'Content-Type': 'application/json' } })
			.subscribe((res) => this.captchaRes = res);
	}

	public sendForm()
	{
		if(this.form.valid)
		{
			UtilsService.sendEnQuery(this.http, this.form.value,
			environment.devUrl + '/modules/frontend_forms/question.form.php').subscribe(result =>
				{
					if(result) {
						this.msgObject.error = result.error || false;
						this.msgObject.error_msg = result.error_msg || '';

						// Reset form.
						this.form.reset();

						setTimeout(() => {
							this.msgObject.error_msg = '';
						}, 5000);
					}
				},
			);
		}
	}
}
