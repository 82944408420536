import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	NbActionsModule,
	NbLayoutModule,
	NbMenuModule,
	NbSearchModule,
	NbSidebarModule,
	NbUserModule,
	NbContextMenuModule,
	NbButtonModule,
	NbSelectModule,
	NbIconModule,
	NbThemeModule,
	NbInputModule,
	NbDatepickerModule,
	NbTimepickerModule,
	NbTabsetModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import { NbDateFnsDateModule } from '@nebular/date-fns';

import {
	RecaptchaModule,
	RecaptchaFormsModule,
	RecaptchaSettings,
	RECAPTCHA_SETTINGS,
	RECAPTCHA_LANGUAGE, RECAPTCHA_BASE_URL,
} from 'ng-recaptcha';

import {
	FooterComponent,
	FooterHomeComponent,
	SubFooterComponent,
	HeaderComponent,
	ContactComponent,
	ReservationComponent,
	ReviewsComponent,
	SearchInputComponent,
	TinyMCEComponent,
	MenuServiceComponent,
	CateringServiceComponent,
} from './components';
import {
	CapitalizePipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
	NumberWithCommasPipe,
} from './pipes';
import {
	OneColumnLayoutComponent,
	ThreeColumnsLayoutComponent,
	TwoColumnsLayoutComponent,

	// Custom
	HeaderLayoutComponent,
	NavbarLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { RouterModule } from '@angular/router';
import { ParallaxModule } from 'ngx-parallax';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlModule } from 'ngx-owl-carousel';
import { environment } from '../../environments/environment';

const NB_MODULES = [
	NbLayoutModule,
	NbMenuModule,
	NbUserModule,
	NbActionsModule,
	NbSearchModule,
	NbSidebarModule,
	NbContextMenuModule,
	NbSecurityModule,
	NbButtonModule,
	NbSelectModule,
	NbIconModule,
	NbEvaIconsModule,
	NbInputModule,
	NbTabsetModule,
	NbDatepickerModule.forRoot(),
	NbDateFnsDateModule.forRoot({ format: 'dd-MM-yyyy HH:mm' }),
	NbTimepickerModule.forRoot(),
];

const LIB_MODULES = [
	FormsModule,
	ReactiveFormsModule,
	ParallaxModule,
	OwlModule,
	NgxPageScrollModule,

	RecaptchaModule,
	RecaptchaFormsModule,
];
const COMPONENTS = [
	HeaderComponent,
	FooterComponent,
	FooterHomeComponent,
	SubFooterComponent,
	ReservationComponent,
	ReviewsComponent,
	ContactComponent,
	SearchInputComponent,
	TinyMCEComponent,
	MenuServiceComponent,
	CateringServiceComponent,
	OneColumnLayoutComponent,
	ThreeColumnsLayoutComponent,
	TwoColumnsLayoutComponent,

	// Custom
	HeaderLayoutComponent,
	NavbarLayoutComponent,
];
const PIPES = [
	CapitalizePipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
	NumberWithCommasPipe,
];

@NgModule({
	imports: [CommonModule, ...NB_MODULES, ...LIB_MODULES, RouterModule],
	exports: [CommonModule, ...PIPES, ...COMPONENTS],
	providers: [
		{
			provide: RECAPTCHA_SETTINGS,
			useValue: environment.recaptcha as RecaptchaSettings,
		},
		{
			provide: RECAPTCHA_BASE_URL,
			useValue: 'https://recaptcha.net/recaptcha/api.js', // use recaptcha.net script source for some of our users
		},
		{
			provide: RECAPTCHA_LANGUAGE,
			useValue: 'nl', // use Dutch language
		},
	],
	declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
	static forRoot(): ModuleWithProviders<ThemeModule> {
		return {
			ngModule: ThemeModule,
			providers: [
				...NbThemeModule.forRoot(
					{
						name: 'default',
					},
					[DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
				).providers,
				// WINDOW_PROVIDERS,
				// { provide: LOCALE_ID, useValue: 'nl-NL' },
			],
		};
	}
}
