import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface CTA
{
	ctaText: string,
	ctaLink: string,
}

@Component({
	selector: 'ngx-header-layout',
	styleUrls: ['header-layout.scss'],
	template: `
		<!-- nav -->
		<ngx-navbar-layout></ngx-navbar-layout>
		<div id="home">
	        <div id="home-slider" class="carousel slide carousel-fade" data-ride="carousel">
	            <div class="carousel-inner">
		            <div class="item active"
		                 style="background-image: url('/assets/images/background-images/front-diner-tables.jpg')">
			            <div class="caption">
				            <h1>{{ headerText }}</h1>
				            <h2>{{ subHeaderText }}</h2>
				            <a *ngIf="shouldScroll" class="btn tv-site-btn pb_letter-spacing-2" pageScroll [routerLink]="['/']" [href]="ctaLink">{{ ctaText }}</a>
				            <a *ngIf="!shouldScroll" class="btn tv-site-btn pb_letter-spacing-2" style="max-width: 300px;line-height: 1.35rem;" [href]="ctaLink" target="_blank">{{ ctaText }}</a>
			            </div>
		            </div>
					<div class="item"
						 style="background-image: url('/assets/images/background-images/gallery_03.jpg')">
						<div class="caption">
							<h1>{{ headerText }}</h1>
							<h2>{{ subHeaderText }}</h2>
							<a *ngIf="shouldScroll" class="btn tv-site-btn pb_letter-spacing-2" pageScroll [routerLink]="['/']" [href]="ctaLink">{{ ctaText }}</a>
							<a *ngIf="!shouldScroll" class="btn tv-site-btn pb_letter-spacing-2" style="max-width: 300px;line-height: 1.35rem;" [href]="ctaLink" target="_blank">{{ ctaText }}</a>
						</div>
					</div>
	                <div class="item"
						 style="background-image: url('/assets/images/background-images/bar-corner.jpg')">
	                    <div class="caption">
	                        <h1>{{ headerText }}</h1>
	                        <h2>{{ subHeaderText }}</h2>
							<a *ngIf="shouldScroll" class="btn tv-site-btn pb_letter-spacing-2" pageScroll [routerLink]="['/']" [href]="ctaLink">{{ ctaText }}</a>
							<a *ngIf="!shouldScroll" class="btn tv-site-btn pb_letter-spacing-2" style="max-width: 300px;line-height: 1.35rem;" [href]="ctaLink" target="_blank">{{ ctaText }}</a>
						</div>
	                </div>
	                <div class="item" style="background-image: url('/assets/images/panda_loempias.jpg')">
	                    <div class="caption">
							<h1>{{ headerText }}</h1>
							<h2>{{ subHeaderText }}</h2>
							<a *ngIf="shouldScroll" class="btn tv-site-btn pb_letter-spacing-2" pageScroll [routerLink]="['/']" [href]="ctaLink">{{ ctaText }}</a>
							<a *ngIf="!shouldScroll" class="btn tv-site-btn pb_letter-spacing-2" style="max-width: 300px;line-height: 1.35rem;" [href]="ctaLink" target="_blank">{{ ctaText }}</a>
						</div>
	                </div>
	                <div class="item"
						 style="background-image: url('/assets/images/background-images/top_floor_seats.jpg')">
	                    <div class="caption">
							<h1>{{ headerText }}</h1>
							<h2>{{ subHeaderText }}</h2>
							<a *ngIf="shouldScroll" class="btn tv-site-btn pb_letter-spacing-2" pageScroll [routerLink]="['/']" [href]="ctaLink">{{ ctaText }}</a>
							<a *ngIf="!shouldScroll" class="btn tv-site-btn pb_letter-spacing-2" style="max-width: 300px;line-height: 1.35rem;" [href]="ctaLink" target="_blank">{{ ctaText }}</a>
	                    </div>
	                </div>
	            </div>
	            <a class="tv-left-control" href="#home-slider" data-slide="prev"><i class="fa fa-angle-left"></i></a>
	            <a class="tv-right-control" href="#home-slider" data-slide="next"><i class="fa fa-angle-right"></i></a>
	            <a pageScroll href="#aboutUs"><i class="fa fa-angle-down"></i></a>
	        </div><!--#home-slider-->
		</div><!--#home-->
        <!-- END nav -->
	`,
})
export class HeaderLayoutComponent implements OnInit
{
	@Input()
	public headerText: string = 'Panda Lemmer';

	@Input()
	public subHeaderText: string = 'Chinees-Indisch restaurant!';

	@Input()
	public showCTA: boolean = true;

	@Input()
	public ctaText: string = 'Reserveer een tafel';

	@Input()
	public ctaLink: string = '#reservation';

	@Input()
	public overrideCTA: boolean = false;

	public shouldScroll: boolean = true;

	constructor(private http: HttpClient) { }

	public ngOnInit()
	{
		// #main-slider
		const slideHeight = $(window).height();
		$('#home-slider .item').css('height',slideHeight);

		$(window).resize(function() {
			'use strict'
			$('#home-slider .item').css('height',slideHeight);
		});

		this.http.get<CTA>('assets/data/cta.json').subscribe((v) =>
		{
			if(this.overrideCTA)
				return;

			this.ctaText = v.ctaText;
			this.ctaLink = v.ctaLink;
			this.shouldScroll = this.ctaLink.includes('#');
		});

		/*
			document.querySelectorAll('a[href^="#"]').forEach(anchor => {
				anchor.addEventListener('click', function (e) {
					e.preventDefault();

					document.querySelector(this.getAttribute('href')).scrollIntoView({
						behavior: 'smooth'
					});
				});
			});
		 */
	}

	public refresh()
	{
		// #main-slider
		const slideHeight = $(window).height();
		$('#home-slider .item').css('height',slideHeight);

		$(window).resize(function() {
			'use strict'
			$('#home-slider .item').css('height',slideHeight);
		});

	}
}
