import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface Review {
	name: string;
	review: string;
	stars: number;
}

@Component({
	selector: 'ngx-reviews',
	templateUrl: 'review.component.html',
	styleUrls: ['review.component.scss'],
})
export class ReviewsComponent implements OnInit, OnDestroy
{
	public title = 'owlcarouselinAngular';
	public slideOptions = { items: 1, dots: true, nav: false };
	public reviews: BehaviorSubject<Review[]> = new BehaviorSubject([]);

	protected subscription: Subscription = new Subscription();

	constructor(private http: HttpClient) { }

	public ngOnInit(): void
	{
		this.subscription.add(this.http.get<Review[]>('assets/data/reviews.json').subscribe(v =>
		{
			this.reviews.next(v);
		}));
	}

	public ngOnDestroy(): void
	{
		this.subscription.unsubscribe();
	}
}
