import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Menu } from '@app-core/data/menu';

@Component({
	selector: 'ngx-menu-service',
	templateUrl: './menu-service.component.html',
	styleUrls: ['./menu-service.component.scss'],
})
export class MenuServiceComponent implements OnInit, AfterViewInit
{
	@Input()
	public overlay: boolean = true;

	@Input()
	public bgImage: string = 'assets/images/background-images/bamboo-lowres.jpg';

	public menu$:
		BehaviorSubject<Menu> = new BehaviorSubject(null);

	constructor(private http: HttpClient) { }

	public ngOnInit()
	{
		this.http.get<Menu>('assets/data/menu.json').subscribe((v) =>
		{
			const menu = v;
			menu.menus = v.menus.filter((m) => m.enabled);
			menu.specials = v.specials.filter((s) => s.enabled);

			this.menu$.next(menu);
		});
	}

	public ngAfterViewInit(): void
	{
		/*------------------
		Background Set
		--------------------*/
		$('.set-bg').each(function() {
			const bg = $(this).data('setbg');
			$(this).css('background-image', 'url(' + bg + ')');
		});

		$('.set-bg-color').each(function() {
			const bg = $(this).data('set-bg-color');
			$(this).css('background-color', bg);
		});

		$('#tohash').on('click', function(){
			$('html, body').animate({scrollTop: $(this.hash).offset().top - 5}, 1000);
			return false;
		});
	}
}
