import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '@app-core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '@app-core/data/users.service';
import { User } from '@app-core/data/users';

@Component({
	selector: 'ngx-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

	private destroy$: Subject<void> = new Subject<void>();
	userPictureOnly: boolean = false;
	user: User = null;

	themes = [
		{
			value: 'default',
			name: 'Light',
		},
		{
			value: 'dark',
			name: 'Dark',
		},
		{
			value: 'cosmic',
			name: 'Cosmic',
		},
		{
			value: 'corporate',
			name: 'Corporate',
		},
	];

	currentTheme = 'default';

	userMenu = [{title: 'Profile'}, {title: 'Log out'}];

	constructor(private sidebarService: NbSidebarService,
				private menuService: NbMenuService,
				private themeService: NbThemeService,
				private userService: UserService,
				private layoutService: LayoutService,
				private breakpointService: NbMediaBreakpointsService) {
	}

	ngOnInit() {
		this.currentTheme = this.themeService.currentTheme;

		this.userService.getUser()
			.pipe(takeUntil(this.destroy$))
			.subscribe((user: User) => this.user = user);

		const {xl} = this.breakpointService.getBreakpointsMap();
		this.themeService.onMediaQueryChange()
			.pipe(
				map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
				takeUntil(this.destroy$),
			)
			.subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

		this.themeService.onThemeChange()
			.pipe(
				map(({name}) => name),
				takeUntil(this.destroy$),
			)
			.subscribe(themeName => this.currentTheme = themeName);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	changeTheme(themeName: string) {
		this.themeService.changeTheme(themeName);
	}

	toggleSidebar(): boolean {
		this.sidebarService.toggle(true, 'menu-sidebar');
		this.layoutService.changeLayoutSize();

		return false;
	}

	navigateHome() {
		this.menuService.navigateHome();
		return false;
	}
}
