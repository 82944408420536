import { Observable } from 'rxjs';
import { UtilsService } from '@app-core/utils';

export interface Roles {
	author?: boolean,
	admin?: boolean,
	reader?: boolean,
}

interface IUserData {
	displayName?: string;
	email: string;
	firstName: string;
	lastName: string;
	photoURL?: string;
	created_at: Object;
	updated_at: Object;
}

export interface User
{
	/**
	 * @brief User Unique ID
	 */
	uid: string;

	/**
	 * @brief - User Metadata
	 */
	metadata: IUserData;

	/**
	 * @brief - Project data
	 * This explains which projects the player
	 * belongs to.
	 */
	pages: { [key: string]: { roles: Roles } };

	articles: { [key: string]: { roles: Roles } };
}

export interface Contacts
{
	user: User;
	type: string;
}

export interface RecentUsers extends Contacts
{
	time: number;
}

export class UserModel implements User
{
	uid: string = '';

	public metadata: IUserData = {
		displayName: '',
		email: '',
		firstName: '',
		lastName: '',
		photoURL: '',
		created_at: UtilsService.timestamp,
		updated_at: UtilsService.timestamp,
	}

	// Standard give the user privileges to access the project,
	// and read them.
	public pages: { [key: string]: { roles: Roles } } = { };

	public articles: { [key: string]: { roles: Roles } } = { };
}

export abstract class UserData
{
	abstract setUser(key: string, newUser: User, current: boolean);

	abstract getUser(): Observable<User>;

	abstract getMembers(): Observable<User[]>;

	abstract getRecentUsers(): Observable<RecentUsers[]>;

	abstract getContacts(): Observable<Contacts[]>;
}

